@import "./variables";

.f-table-wrapper {
  .scrollbar-container {
    max-height: 80vh;
    // padding: 2rem;
  }
  .f-table {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;

    // > .row {
    //   max-height: 70vh;
    //   overflow-y: auto;
    // }
    .controlRow__root {
      display: flex;
      justify-content: flex-end;
      margin-top: 3rem;
      .ButtonGroup__root {
        button {
          border: 0;
          border-radius: 100px;
          background: none;
          color: #ffffff;
          &:disabled {
            background: $themePrimaryColor !important;
            color: #1c2229;
            font-weight: 700;
            opacity: 0.75;
            width: 40px;
            min-width: 40px;
            height: 40px;
          }
          &:first-child,
          &:nth-child(2),
          &:last-child,
          &:nth-last-child(2) {
            background: none !important;
            color: #ffffff !important;
            width: auto !important;
          }
          &:nth-child(2) {
            margin-right: 0.5rem;
          }
          &:nth-last-child(2) {
            margin-left: 0.5rem;
          }
          &:first-child,
          &:last-child {
            background-image: url("./img/f-icons/f-icon-pagination.svg") !important;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            background-size: 10px;
            text-indent: -10000px;
            overflow: hidden;
          }
          &:last-child {
            transform: rotate(180deg);
            vertical-align: -2px;
          }
        }
      }
    }
    table {
      width: 100%;
      margin-bottom: 1rem;
      color: white;
      thead,
      tbody {
        tr {
          td {
            padding: 0.5rem 1rem;
            // border-bottom-width: 1px;
            // border-right-width: 0;
            // border-style: solid;
            // border-color: #eee;
            border: 0;
            word-break: break-all;
            min-width: 100px;
            vertical-align: middle;
            font-size: $tableFontSize;
            line-height: 1.25;
            min-height: 70px;
          }
          td:first-child {
            border-top-left-radius: $borderRadiusBase * 2;
            border-bottom-left-radius: $borderRadiusBase * 2;
          }
          td:last-child {
            border-top-right-radius: $borderRadiusBase * 2;
            border-bottom-right-radius: $borderRadiusBase * 2;
          }
        }
      }
      thead {
        position: sticky;
        top: 0;
        z-index: $zIndex;
        background: #242b35 !important;
        border-radius: $borderRadiusBase * 2;
        tr {
          td {
            font-weight: bold;
            height: 70px;
            // color: black;
          }
        }
      }
      tbody {
        tr {
          &:nth-child(odd) {
            td {
              background: #392855 !important;
            }
          }
        }
      }
    }
    &.sticky-header {
      thead {
        background: #242b35;
        td {
          color: $themePrimaryColor;
        }
      }
    }
  }
}
.f-table-whiteLabeled-wrapper {
  .f-table {
    .controlRow__root {
      margin-top: 0rem;
    }
    table {
      thead,
      tbody {
        tr {
          td {
            font-size: 16px;
            font-weight: 600;
          }
          td:first-child {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
          }
          td:last-child {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          }
        }
      }
      thead {
        background: $purpleCard !important;
        tr {
          td {
            font-weight: 400;
            height: 40px;
          }
        }
      }
      tbody {
        tr {
          &:nth-child(odd) {
            td {
              background: rgb(36, 35, 35);
            }
          }
        }
      }
    }
    &.sticky-header {
      thead {
        background: $purpleCard;
        td {
          color: $themePrimaryColor;
        }
      }
    }
  }
}

.f-table-futuristic-wrapper {
  .scrollbar-container {
    max-height: inherit;
  }
  .f-table-futuristic-primary {
    padding: 3rem;
    position: relative;
    .controlRow__root {
      position: absolute;
      bottom: -4rem;
      right: 0;
      &:before,
      &:after {
        display: none;
      }
    }

    > .row {
      // position: relative;
      &:before,
      &:after {
        content: "";
        width: 50.25%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
      }
      &:before {
        background: url("./img/table-futuristic-primary.svg") left center no-repeat;
        background-size: cover;
        left: 0;
      }
      &:after {
        background: url("./img/table-futuristic-primary.svg") left center no-repeat;
        background-size: cover;
        transform: rotate(-180deg) scaleY(-1);
        right: 0;
      }
    }
  }
}
